import React from "react";
import { PlatformIcon } from "platformicons";
import { Nav } from "react-bootstrap";

import "../css/screen.scss";

import SEO from "../components/seo";
import Search from "../components/search";
import SmartLink from "../components/smartLink";
import { usePlatformList } from "../components/hooks/usePlatform";
import NavbarPlatformDropdown from "../components/navbarPlatformDropdown";

import SentryWordmarkSVG from "../logos/sentry-wordmark-dark.svg";

import Banner from "../components/banner";
import { getSandboxURL, SandboxOnly } from "~src/components/sandboxLink";

const HIGHLIGHTED_PLATFORMS = [
  "javascript",
  "node",
  "python",
  "php",
  "ruby",
  "java",
  "javascript.react",
  "react-native",
  "python.django",
  "dotnet",
  "go",
  "php.laravel",
  "android",
  "apple",
  "javascript.nextjs",
  "ruby.rails",
  "flutter",
  "unity"
];

const IndexPage = () => {
  const platformList = usePlatformList();

  let totalPlatformCount = 0;
  const visiblePlatforms = [];
  platformList.forEach(platform => {
    totalPlatformCount += 1;
    if (HIGHLIGHTED_PLATFORMS.indexOf(platform.key) !== -1) {
      visiblePlatforms.push(platform);
    }
    platform.guides.forEach(guide => {
      totalPlatformCount += 1;
      if (HIGHLIGHTED_PLATFORMS.indexOf(guide.key) !== -1) {
        visiblePlatforms.push(guide);
      }
    });
  });
  visiblePlatforms.sort(
    (a, b) =>
      HIGHLIGHTED_PLATFORMS.indexOf(a.key) -
      HIGHLIGHTED_PLATFORMS.indexOf(b.key)
  );

  return (
    <div className="index-wrapper">
      <SEO title="DocBase AI Documentation" />
      <div className="hero-section" >
        <div className="index-container">
          <div className="index-navbar" style={{ marginBottom: '10rem' }} >
            <a href="/" title="Sentry error monitoring" className="index-logo">
              <img src="/DocBase_logo.svg" alt='image' style={{ width: 200 }} />
            </a>
            <Nav className="justify-content-end" style={{ flex: 1 }}>
              {/* <Nav.Item>
                <SmartLink className="nav-link" to="/api/">
                  API
                </SmartLink>
              </Nav.Item> */}
              <SandboxOnly>
                <Nav.Item>
                  <Nav.Link className="text-primary" href="https://docbase.ai/" target="_blank">Demo</Nav.Link>
                </Nav.Item>
              </SandboxOnly>
              <Nav.Item>
                <Nav.Link href="https://app.docbase.ai/">
                  Đăng nhập
                  <svg
                    width="1em"
                    height="1em"
                    viewBox="0 0 16 16"
                    className="bi bi-arrow-right-short"
                    fill="currentColor"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z"
                    />
                  </svg>
                </Nav.Link>
              </Nav.Item>
            </Nav>
          </div>



          <h1>DocBase AI Documentation</h1>
          {/* <div className="index-search">
              <Search />
            </div> */}

          <div className="flex-row card-row" style={{ maxWidth: 600, margin: '0 auto 0' }} >
            <a className="hover-card-link" href="/guides/">
              Hướng dẫn sử dụng
            </a>
            <a className="hover-card-link" href="/guides/integrations">
              Hướng dẫn tích hợp
            </a>
          </div>


        </div>
        <Banner />
      </div>
    </div>
  );
};

export const frontmatter = {
  title: "Sentry Documentation",
};

export default IndexPage;
